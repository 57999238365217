@media all and (max-width: 2560px) { 
  body, html {
    background-color: #1a1f23;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .budtopia-image {
    width: 80%;
    max-width: 1000px;
    display: flex;
  }

  .coming-soon-text {
    color: white;
    text-align: center;
    font-family: Rubik, sans-serif;
    font-size: 1.75rem;
    font-weight: bold;
    transform: translateY(-200%);
  }
}

@media all and (max-width: 768px) { 
  body, html {
    background-color: #1a1f23;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .budtopia-image {
    width: 80%;
    max-width: 1000px;
    display: flex;
  }

  .coming-soon-text {
    color: white;
    text-align: center;
    font-family: Rubik, sans-serif;
    font-size: 1.5rem;
    font-weight: bold;
    transform: translateY(-180%);
  }
}

@media all and (max-width: 431px) { 
  body, html {
    background-color: #1a1f23;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .budtopia-image {
    width: 80%;
    max-width: 1000px;
    display: flex;
  }

  .coming-soon-text {
    color: white;
    text-align: center;
    font-family: Rubik, sans-serif;
    font-size: 1.2rem;
    font-weight: bold;
    transform: translateY(-150%);
  }
}

@media all and (max-width: 376px) { 
  body, html {
    background-color: #1a1f23;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .budtopia-image {
    width: 80%;
    max-width: 1000px;
    display: flex;
  }

  .coming-soon-text {
    color: white;
    text-align: center;
    font-family: Rubik, sans-serif;
    font-size: 1.1rem;
    font-weight: bold;
    transform: translateY(-140%);
  }
}


@media all and (max-width: 321px) { 
  body, html {
    background-color: #1a1f23;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .budtopia-image {
    width: 80%;
    max-width: 1000px;
    display: flex;
  }

  .coming-soon-text {
    color: white;
    text-align: center;
    font-family: Rubik, sans-serif;
    font-size: 1rem;
    font-weight: bold;
    transform: translateY(-130%);
  }
}
