::-webkit-scrollbar {
    width: 10px;
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: #1c232b;
    border: 2.5px solid #212832;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:active {
    background: #212832;
}

body {
    scrollbar-width: thin;
    scrollbar-color: #1b1d20 transparent;
}